import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import App from './router/App';
import reportWebVitals from './reportWebVitals';

import 'video.js/dist/video-js.css';


class ErrorBoundary extends React.Component {
    componentDidCatch(error:any, errorInfo:any) {
        console.error("Error caught in ErrorBoundary:", error, errorInfo);
    }
    render() {
        return this.props.children;
    }
}


ReactDOM.render(

  <React.StrictMode>
      <ErrorBoundary>
         <App />
      </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
